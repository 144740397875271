<template>
    <div class="px-3 mb-3">
        <div class="card-ticket">
            <div class="row">
                <div class="xs12 pa-3 pb-2">
                    <div :class="rowText">
                        <div class="mr-2">
                            <i class="mdi mdi-calendar-clock"></i>
                        </div>
                        <div :class="textRight">
                            {{
                                moment(item.thoiGianXuatBenKeHoach).format(
                                    "HH:mm DD/MM/yyyy",
                                )
                            }}
                        </div>
                    </div>
                    <div :class="rowText">
                        <div class="mr-2">
                            <i class="mdi mdi-card-bulleted-outline"></i>
                        </div>
                        <div :class="textRight">
                            {{ item.bienKiemSoat }}
                            (<span @click="XemBanTheHienLenh(item)" class="text-link"
                                >{{ item.maLenh }} </span
                            >)
                        </div>
                    </div>
                    <div :class="rowText">
                        <div class="mr-2">
                            <i class="mdi mdi-road-variant"></i>
                        </div>
                        <div :class="textRight">
                            {{ `${item.tenTuyen} (${item.maTuyen})` }}
                        </div>
                    </div>
                    <div :class="rowText">
                        <div class="mr-2">
                            <i class="mdi mdi-bus-stop"></i>
                        </div>
                        <div :class="textRight">
                            {{ item.tenBenXe }}
                        </div>
                    </div>
                    <div :class="rowLastText">
                        <div class="mr-2">
                            <i class="mdi mdi-card-account-details-outline"></i>
                        </div>
                        <div :class="textRight">
                            {{ item.tenLaiXe }}
                        </div>
                    </div>
                    <!-- <div :class="rowText">
                    <div class="mr-2">
                        <i class="mdi mdi-list-status"></i>
                    </div>
                    <div :class="textRight" :style="`color: ${item.maMauTrangThai}`">
                        {{ item.tenTrangThai }}
                    </div>
                </div> -->
                </div>
            </div>

            <div class="row">
                <div class="xs12">
                    <div
                        class="row"
                        style="border-top: 1px solid #dadce0; padding: 0 2px"
                        v-if="item.idTrangThai == 1"
                    >
                        <div class="xs12 text-xs-center">
                            <!-- style="border-right: 1px solid #dadce0" -->
                            <DxButton
                                text="Chọn lệnh này"
                                type="default"
                                styling-mode="text"
                                width="100%"
                                @click="ChonLenhChuyen(item)"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DxButton } from "devextreme-vue";
export default {
    components: {
        DxButton,
    },
    props: {
        item: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            rowText: "row mb-1 align-center row-text",
            rowLastText: "row align-center row-text",
            textRight: " font-medium",
        };
    },
    methods: {
        ChonLenhChuyen(item) {
            this.$emit("ChonLenhChuyen", item);
        },
        XemBanTheHienLenh(item) {
            this.$emit("XemBanTheHienLenh", item);
        },
    },
};
</script>

<style scoped>
.card-ticket {
    position: relative;
    box-shadow: 0px 2px 9px #ccc;
    overflow: hidden;
}
.row-text {
    word-break: break-word;
    white-space: break-spaces;
}
</style>
